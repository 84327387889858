import axios from 'axios';

export const client = axios.create({
	baseURL:
		process.env.NODE_ENV === 'development'
			? 'http://127.0.0.1:5000/archive'
			: '/archive',
});

export async function getIndex() {
	const response = await client.get<{ index: Array<string> }>('/');
	const { index } = response.data;
	return index;
}

export async function getBook(bookId: string) {
	const response = await client.get<{ text: string }>(`/books/${bookId}`);
	const { text } = response.data;
	return text;
}
