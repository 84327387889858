import React, { useEffect, useState } from 'react';
import { getIndex } from '../services/archive';
import { Link } from 'react-router-dom';

function App() {
	const [index, setIndex] = useState<Array<string>>([]);

	useEffect(() => {
		getIndex().then(index => setIndex(index));
	}, []);

	return (
		<div className="flex flex-col items-center space-y-4 p-4">
			<p className="font-bold">Archive</p>
			<ul className="list-disc">
				{index.map(item => (
					<li key={item}>
						<Link to={`books/${item}`}>{item}</Link>
					</li>
				))}
			</ul>
		</div>
	);
}

export default App;
