import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

interface MarkdownProps {
	content: string;
}

export default function Markdown({ content }: MarkdownProps) {
	return (
		<ReactMarkdown
			className="prose prose-invert max-w-none"
			remarkPlugins={[remarkGfm]}
		>
			{content}
		</ReactMarkdown>
	);
}
