import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getBook } from '../services/archive';
import Markdown from '../components/Markdown';
import { Loader } from '../components/Loader';

export default function Book() {
	const { bookId } = useParams<{ bookId: string }>();

	const [text, setText] = useState('');

	useEffect(() => {
		if (bookId) getBook(bookId).then(text => setText(text));
	}, [bookId]);

	return (
		<div className="p-4">{text ? <Markdown content={text} /> : <Loader />}</div>
	);
}
