import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ArchiveIndex from './pages/ArchiveIndex';
import Book from './pages/Book';

function App() {
	return (
		<Router>
			<Routes>
				<Route path="/" Component={ArchiveIndex} />
				<Route path="/books/:bookId" Component={Book} />
			</Routes>
		</Router>
	);
}

export default App;
